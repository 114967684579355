import React, { useState } from "react"

import LinkButton from "../components/link_button"
import useFeedbackCollector from "../hooks/use_feedback_collector"

const FeedbackCard = ({ location }) => {
  const {
    success,
    loading,
    error: submissionError,
    submit,
  } = useFeedbackCollector()

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState(false)

  const isValidEmail = input => {
    // eslint-disable-next-line prettier/prettier
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(input).toLowerCase())
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
    if (error) {
      const valid = isValidEmail(e.target.value)
      if (valid) {
        setError(false)
      }
    }
  }

  const handleMessageChange = e => {
    setMessage(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    typeof window.gtag !== "undefined" &&
      window.gtag("event", "click", {
        category: "community",
        action: "email_submit",
        label: "signup",
      })

    const valid = isValidEmail(email)

    if (!valid) {
      setError(true)
      return
    }

    if (valid) {
      setError(false)
      submit(email, location, message)
      setMessage("")
      setEmail("")
    }
  }

  return (
    <div className="w-full lg:w-96 p-4 text-charcoal border border-charcoal bg-white rounded-lg">
      <h3 className="pb-2">We want your thoughts</h3>
      <p className="leading-tight">
        We do a lot of research on industries, but we’re not in the day-to-day
        operations. If you have more insight and are willing to share, please
        contact us!
      </p>
      
          <LinkButton
            className="w-full my-2 text-white bg-ptRed hover:scale-105 transition-all transform duration-200"
            href="https://airtable.com/shrhNIfaxoGd4X65T"
            size="lg"
          >
            Connect with Us
          </LinkButton>
    </div>
  )
}

export default FeedbackCard
