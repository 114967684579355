import * as React from "react"

function TwitterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.32 2.63l.825.096-.371.744-.543 1.087a.86.86 0 01-.115.232l-.107.159a1.197 1.197 0 00-.048.076v1.394c0 3.565-3.45 5.83-6.709 5.83-.667 0-1.173-.031-1.753-.158-.955-.208-1.665-.622-1.99-1.346l-.335-.745.813-.075c.736-.068 1.374-.208 1.864-.396-1.345-.216-2.099-.807-2.099-1.916v-.583h.583c.129 0 .244-.01.347-.03-1.009-.603-1.515-1.572-1.515-2.915v-.118c-.004-.677.032-1.039.218-1.5.119-.296.29-.57.521-.821l.44-.478.428.489c1.41 1.612 2.805 2.59 4.228 2.708.007-1.487 1.13-2.613 2.626-2.613a2.6 2.6 0 011.825.742c.272.055.561.101.867.137zm-1.29.964l-.179-.04-.123-.136a1.452 1.452 0 00-1.1-.5c-.854 0-1.459.608-1.459 1.458 0 .139-.004.226-.02.34-.07.459-.327.826-.855.826-1.749 0-3.327-.936-4.865-2.537-.08.242-.098.49-.095.954v.125c0 1.195.484 1.848 1.63 2.213l.407.129v.426c0 .518-.49 1.005-1.208 1.223.329.25 1.001.384 2.09.384h.583v.583c0 .836-.96 1.49-2.411 1.832.473.145 1.103.208 1.827.208 2.692 0 5.542-1.872 5.542-4.665V4.96c0-.171.05-.32.133-.475.032-.06.067-.118.111-.184l.063-.093.047-.092.224-.45a9.845 9.845 0 01-.342-.07z"
        fill="#000"
      />
    </svg>
  )
}

export default TwitterIcon
