import * as React from "react"

function LinkedInIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.333 1.167h9.334c.644 0 1.166.522 1.166 1.166v9.334c0 .644-.522 1.166-1.166 1.166H2.333a1.167 1.167 0 01-1.166-1.166V2.333c0-.644.522-1.166 1.166-1.166zm0 1.166v9.334h9.334V2.333H2.333zm5.25 2.917c-.302 0-.62.092-.903.264l-.263-.264h-.584v4.083H7V7c0-.336.346-.583.583-.583h.584c.237 0 .583.247.583.583v2.333h1.167V7c0-1.08-.937-1.75-1.75-1.75h-.584zm-2.916-.583a.583.583 0 100-1.167.583.583 0 000 1.167zm-.584 4.666H5.25V5.25H4.083v4.083z"
        fill="#000"
      />
    </svg>
  )
}

export default LinkedInIcon
