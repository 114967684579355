import * as React from "react"

function GithubIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.843 11.669a.84.84 0 01-.02.212 1.266 1.266 0 01-.15.378 1.142 1.142 0 01-1.006.576c-1.062 0-1.479-.52-2-1.825-.354-.883-.52-1.092-.917-1.092V8.752c1.062 0 1.478.52 2 1.825.353.883.52 1.091.917 1.091 0-.169-.003-.319-.008-.522-.011-.476-.012-.573.008-.7.009-.278.08-.475.224-.67-1.302-.284-2.18-.87-2.684-1.818l-.188-.444c-.184-.529-.269-1.128-.269-1.82 0-.796.243-1.508.697-2.104-.142-.566-.123-1.257.19-2.038l.102-.255.262-.084a.86.86 0 01.127-.029c.51-.08 1.235.11 2.184.718a7.738 7.738 0 011.74-.195c.53 0 1.056.054 1.559.159.922-.578 1.628-.759 2.124-.682.05.008.091.018.126.029l.264.083.102.257c.273.686.32 1.319.22 1.873.522.625.803 1.395.803 2.268 0 .737-.053 1.291-.204 1.83l-.16.44c-.42.948-1.344 1.54-2.77 1.822.148.205.217.413.217.716v1.166c.001.021.003.032.005.038l-.005 1.13c-.497 0-.866-.243-1.048-.625a1.207 1.207 0 01-.118-.55V10.5c0-.048-.002-.05-.121-.17-.319-.318-.463-.559-.463-.996v-.528l.525-.052c1.563-.157 2.413-.591 2.695-1.225l.132-.36c.105-.377.148-.835.148-1.476 0-.68-.237-1.242-.686-1.69l-.247-.247.1-.335a2 2 0 00.017-1.048 2.162 2.162 0 00-.05.013c-.312.087-.702.273-1.172.587l-.214.144-.25-.062a6.395 6.395 0 00-1.53-.182c-.583 0-1.16.074-1.693.22l-.26.07-.223-.152c-.486-.332-.89-.53-1.214-.622a2.157 2.157 0 00-.066-.018c-.113.464-.068.845.046 1.13l.137.343-.25.271c-.4.431-.611.956-.611 1.578 0 .57.066 1.042.189 1.398l.152.361C3.64 8.17 4.45 8.6 5.896 8.755l.52.056v.524c0 .437-.143.678-.462.996-.119.12-.12.122-.12.17l-.01.109c-.007.035-.007.126.002.508.004.176.006.315.007.46.007.03.008.05.01.09z"
        fill="#000"
      />
    </svg>
  )
}

export default GithubIcon
