import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import GithubIcon from "../svg/github_icon"
import LinkedInIcon from "../svg/linkedin_icon"
import TwitterIcon from "../svg/twitter_icon"

const AboutUs = () => {
  return (
    <div className="px-4 pb-16 lg:max-w-xl">
      <div className="flex flex-wrap">
        <div className="w-24">
          <StaticImage
            className="w-full rounded-full"
            src="../images/bc_avatar.png"
            alt="Brian Chamberlain"
          />
        </div>
        <div className="flex-auto lg:pr-16">
          <h3>Brian Chamberlain</h3>
          <h5>Founder, Engineering</h5>
          <p className="flex-wrap leading-tight py-1">
            Brian has been building consumer web and mobile products since 2000.
            Now in the Web3 space he is looking to bring his product engineering
            skills to the distributed web. His interests are in protocol development,
            applied cryptography, decentralized storage, hardware and software prototyping. 
          </p>
          <div className="flex-wrap max-w-lg uppercase text-charcoal text-sm pt-2">
            <span className="pr-4 whitespace-nowrap">
              <TwitterIcon className="inline m-1" />
              <a
                href="https://twitter.com/etherbc"
                className="underline font-bold"
              >
                @etherbc
              </a>
            </span>
            <span className="pr-4 whitespace-nowrap">
              <LinkedInIcon className="inline m-1" />
              <a
                href="https://www.linkedin.com/in/blchamberlain/"
                className="underline font-bold"
              >
                /blchamberlain
              </a>
            </span>
            <span className="lg:pr-4 whitespace-nowrap">
              <GithubIcon className="inline m-1" />
              <a
                href="https://github.com/brian-lc"
                className="underline font-bold"
              >
                @brian-lc
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap pt-16">
        <div className="w-24">
          <StaticImage
            className="w-full rounded-full"
            src="../images/tj_avatar.png"
            alt="T.J. Chmielewski"
          />
        </div>
        <div className="flex-auto lg:pr-16">
          <h3>T.J. Chmielewski</h3>
          <h5>Founder, Product Design</h5>
          <p className="flex-wrap leading-tight py-1">
            T.J. specializes in defining successful products and experiences,
            building effective prototypes and tests, and improving product
            development processes through collaboration. :)
          </p>
          <div className="flex-auto max-w-lg uppercase text-charcoal text-sm pt-2">
            <span className="pr-4 whitespace-nowrap">
              <TwitterIcon className="inline m-1" />
              <a
                href="https://twitter.com/tj34dotcom"
                className="underline font-bold"
              >
                @tj34dotcom
              </a>
            </span>
            <span className="pr-4 whitespace-nowrap">
              <LinkedInIcon className="inline m-1" />
              <a
                href="https://www.linkedin.com/in/tjchmielewski/"
                className="underline font-bold"
              >
                /tjchmielewski
              </a>
            </span>
            <span className="lg:pr-4 whitespace-nowrap">
              <GithubIcon className="inline m-1" />
              <a
                href="https://github.com/tjchmielewski"
                className="underline font-bold"
              >
                @tjchmielewski
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
